import React, { useEffect, useState } from "react";

import { FormControl, FormHelperText, FormLabel, Checkbox, Box, List, ListItem } from "@mui/joy";
import { Controller } from "react-hook-form";

const InputCheckbox = (props) => {
    const { editing, required, name, control, label, autoFocus, type = "checkbox", hint, haserror, options, className = "" } = props
    let { disabled, readonly } = props;
    if (!editing) readonly = true;
    if (readonly) disabled = true;

   

    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelect = (value) => {
        console.log('handleSelect', value);

        const isPresent = selectedItems.indexOf(value);
        if (isPresent !== -1) {
            const remaining = selectedItems.filter((item) => item !== value);
            setSelectedItems(remaining);
        } else {
            setSelectedItems((selectedItems) => [...selectedItems, value]);
        }
    };

    const generateOptions = (onChange) => {
        return options.map((option, k) => (
            <ListItem key={k}>
                <Checkbox
                    key={k}
                    value={option.value}
                    label={option.label}
                    checked={selectedItems.includes(option.value)}
                    onChange={() => {
                        handleSelect(option.value);
                        onChange();
                    }}

                    id={name}
                    type={type}
                    name={name}
                    variant="outlined"
                    autoComplete="off"

                    autoFocus={autoFocus}
                    disabled={disabled}
                    readOnly={readonly}
                    required={required}
                />
            </ListItem>
        ));
    };

    return (

        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) =>  {
                let generalClass = "form-element-control";
                generalClass += (editing) ? " editing" : " reading";
                generalClass += (invalid) ? " error" : "";
                generalClass += (required) ? " required" : "";
                generalClass += (disabled) ? " disabled" : "";
                generalClass += (readonly) ? " readonly" : "";
                return (
                <div className={("form-element checkbox " + className).trim()}>
                    <Box className={(error) ? generalClass + " form-element-error" : generalClass}>
                        <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>
                        <Box className="form-element-field">
                            <List error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass} role="group">
                                {generateOptions(onChange)}
                                <FormHelperText className="form-element-hint">
                                    {(haserror || error) ? (
                                        <>
                                            {error ? error.message : 'Messaggio di errore'}
                                        </>
                                    ) : (
                                        <>
                                            {hint}
                                        </>
                                    )}
                                </FormHelperText>
                            </List>
                        </Box>
                    </Box>
                </div>
            )}}
        />

    );
};


export default InputCheckbox;
