
const initialStatus = {
    widgets: [],
    items: [],
    total: 0,
    item: null,
    tableConfig: [],
    formConfig: {},
    loading: false,
    error: false,
};

const createReducer = (constants) => {

    function reducer(state = initialStatus, action) {
        switch (action.type) {

            case constants.SHOW_NEW:
                const k = state.items.findIndex(item => {
                    return item.id === action.id
                });

                if (k < 0) {
                    return {
                        ...state,
                        item: {
                            id: null,
                            name: '',
                        },
                    };
                } else {
                    return {
                        ...state,
                        item: state.items[k],
                    };
                }
                break;

            case constants.CLOSE_DETAIL:
                return {
                    ...state,
                    item: null,
                };

            case constants.SAVE_REQUEST:
                return {
                    ...state,
                    errors: {},
                    errorMessage :null,
                    loading: true,
                    doingAction: true,

                };

            case constants.SAVE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                };


            case constants.SAVE_FAILURE:
                console.log("SAVE_FAILURE", action)
                return {
                    ...state,
                    loading: false,
                    errors: action.errors,
                    errorMessage: action.message,
                };
                break;


            case constants.DELETE_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
            case constants.DELETE_SUCCESS:
                return {
                    ...state,
                    loading: false,
                };
                break;

            case constants.DELETE_FAILURE:
                return {
                    ...state,
                    loading: false,
                };


            case constants.GETALL_REQUEST:
                return {
                    ...state,
                    loading: true
                };

            case constants.GETALL_SUCCESS:
                return {
                    ...state,
                    widgets: action.items.widgets,
                    items: action.items.data,
                    tableConfig: action.items.tableConfig,
                    formConfig: action.items.formConfig,
                    total: action.items.meta.total,
                    loading: false,

                };
                break;

            case constants.GETALL_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    loading: false
                };

           
            case constants.GETONE_REQUEST:
                return {
                    ...state,
                    item: {
                        data: {}
                    },
                    errors: {},
                    errorMessage :null,

                    loading: true
                };
            case constants.GETONE_SUCCESS:
                return {
                    ...state,
                    item: action.item,
                    loading: false
                };

            case constants.GETONE_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    loading: false
                };

            default:
                return state;
        }
    }
    return {
        reducer
    }
}

export default createReducer