import React from "react"

import { FormControl, FormHelperText, FormLabel, Switch } from "@mui/joy";
import { Controller } from "react-hook-form";
import { Icon } from '../../assets';

const InputSwitch = (props) => {
    const { editing, required, name, control, ischecked, label, autoFocus, hint, icon, haserror, checkedlabel, uncheckedlabel, className = "" } = props
    let { readonly, disabled } = props;
    if (!editing) readonly = true;
    if (readonly) disabled = true;

    const [checked, setChecked] = React.useState(ischecked);



    return (

        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {
                let generalClass = "form-element-control";
                generalClass += (editing) ? " editing" : " reading";
                generalClass += (invalid) ? " error" : "";
                generalClass += (required) ? " required" : "";
                generalClass += (disabled) ? " disabled" : "";
                generalClass += (readonly) ? " readonly" : "";
                return (
                    <div className={("form-element switch " + className).trim()}>
                        <FormControl error={invalid} orientation="horizontal" className={(error) ? generalClass + " form-element-error" : generalClass}>
                            <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>
                            <Switch
                                id={name}
                                name={name}
                                checked={checked}
                                value={value}
                                // error={invalid}
                                onChange={(event) => {
                                    setChecked(event.target.checked);
                                    onChange();
                                }}
                                variant="outlined"
                                autoComplete="off"
                                className="form-element-field"

                                autoFocus={autoFocus}
                                disabled={disabled}
                                readOnly={readonly}
                                required={required}

                                startDecorator={(icon) ? (
                                    <Icon icon={icon} />
                                ) : (
                                    <></>
                                )}
                                endDecorator={(haserror || error) ? (
                                    <>
                                        {checked ? checkedlabel : uncheckedlabel}
                                        <Icon icon="faCircleExclamation" />
                                    </>
                                ) : (
                                    <>{checked ? checkedlabel : uncheckedlabel}</>
                                )}
                            />
                            <FormHelperText className="form-element-hint">
                                {(haserror || error) ? (
                                    <>
                                        {error ? error.message : 'Messaggio di errore'}
                                    </>
                                ) : (
                                    <>
                                        {hint}
                                    </>
                                )}
                            </FormHelperText>
                        </FormControl>
                    </div>
                )
            }}
        />

    );
};


export default InputSwitch;
