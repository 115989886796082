import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellBis = ({ value }) => {
    const limit = 4;
    const length = Object.keys(value).length;
    let more = null;

    if (length > limit) {
        more = <ListItem className={'cell-list-item-count cell-bis-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    return (
        <Box className={"list-element-cell cell-bis"}>
            <List className="cell-bis-list">
                {value.map((bi, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-bis-list-item'}>
                            <span className="name" title={bi.name}>{bi.name.charAt(0)}</span>
                        </ListItem>
                    );
                })}
                {more}
            </List>
        </Box>
    );
}

export default CellBis;