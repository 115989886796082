import React from 'react';

const Debugger = () => {
    return (process.env.NODE_ENV === 'development' && (
        <div className="debugger">
            REACT_APP_API_ENDPOINT:<br/><strong>{process.env.REACT_APP_API_ENDPOINT}</strong>
        </div>
    ));
}

export default Debugger