import React, { useState, useEffect } from 'react';
import { Button } from '../../../mtska-frontend-app-component';
import { useDataProvider } from "../../../mtska-frontend-data-provider"

const Detail = ({ moduleId, dataProviderName, filters, title, label, icon = "" }) => {



    const { item, save, loadOne, formConfig } = useDataProvider(dataProviderName);

    const [defaultValues, setDefaultValues] = useState({})
    const [uid, setUid] = useState(Date.now())


    useEffect(() => {
        if (!filters?.id) return
        loadOne(filters?.id)
    }, [filters])

    useEffect(() => {
        if (!item?.data) return
        setDefaultValues(item.data)
    }, [item])




    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <Button 
                onClick={(e) => {
                    const el = document.getElementById(uid);
                    console.log('manda a full screen ', el);
                    if (el) el.requestFullscreen();
                }} 
                className="drawer-main-action">
                Full screen
                </Button>
            <iframe width="100%" height="100%" src={defaultValues?.url_embed}
                frameborder="0"
                style={{}}
                allowfullscreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                id={uid}
            >
            </iframe>
        </div>
    )

}

export default Detail;


