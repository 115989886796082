import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useDataProviderActions } from './dataProvider';

export function useDataProvider(dataProviderName) {

  const items = useSelector(state => state[dataProviderName]).items
  const total = useSelector(state => state[dataProviderName]).total
  const loading = useSelector(state => state[dataProviderName]).loading
  const widgets = useSelector(state => state[dataProviderName]).widgets
  const item = useSelector(state => state[dataProviderName]).item
  const errorMessage = useSelector(state => state[dataProviderName]).errorMessage
  const errors = useSelector(state => state[dataProviderName]).errors
  const formConfig = useSelector(state => state[dataProviderName]).formConfig
  const tableConfig = useSelector(state => state[dataProviderName]).tableConfig

  // dispatchable actions
  const dispatch = useDispatch();
  const dataProviderActions = useDataProviderActions(dataProviderName)

  const loadAll = (filterModel = {}, sortModel, page, per_page) => {
    dispatch(dataProviderActions.getAll(filterModel,sortModel, page, per_page))
  }

  const loadOne = (id) => {
    return dispatch(dataProviderActions.getOne(id))
  }

  const save = (values) => {
    dispatch(dataProviderActions.save(values))
  }

  return {
    items,
    total,
    loading,
    item,
    errorMessage,
    errors,
    widgets,
    formConfig,
    tableConfig,
    loadAll,
    loadOne,
    save,
  };
}