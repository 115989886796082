import axios from 'axios';
import authHeader from './authHeader';
import { handleError, handleSuccess } from './handleResponse';
import fakeDataCity from '../../../fakeData/city.json';
import fakeDataTeam from '../../../fakeData/team.json';
import fakeDataHooligans from '../../../fakeData/hooligan.json';

let controller

const endpointBase = process.env.REACT_APP_API_ENDPOINT

const UNAUTHORIZED = 401;
// axios.interceptors.response.use(
//     response => response,
//     error => {
//         const { status } = error.response;
//         if (status === UNAUTHORIZED) {
//             console.log('unauthorized')
            
//         }
//         return Promise.reject(error);
//     });

const createService = (endpointPart) => {

    const endpoint = endpointBase + endpointPart

    async function _delete(id) {

        if (endpoint === 'city.json') return
        if (endpoint === 'team.json') return
        if (endpoint === 'hooligan.json') return

        const axiosRequestOptions = {
            url: endpoint + id,
            method: 'DELETE',
            headers: authHeader()
        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
    }

    async function save(data) {

        if (endpoint === 'city.json') return
        if (endpoint === 'team.json') return
        if (endpoint === 'hooligan.json') return



        let method = 'POST';
        let urlId = '';


        if (data.id !== undefined) {
            method = 'PUT';
            urlId = '/' + data.id;
        }

        const axiosRequestOptions = {
            url: endpoint + urlId,
            method: method,
            headers: authHeader(),
            data: data

        };


        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError)
            
        //     res => { 
        //     console.log("axios 1 ", res) 
        //   }) 
        //   .catch(err => { 
        //     console.log("axios 2 ", err) 
        //     if (err.response) { 
                

        //       // client received an error response (5xx, 4xx)
        //     } else if (err.request) { 
                
        //       // client never received a response, or request never left 
        //     } else { 
        //       // anything else 
        //     } 
        //   })
        // return axios(axiosRequestOptions).then(handleResponse, error => {
        //     console.log("axios - error.response", error);
        //   });
    }

    async function getAll(filterModel= {}, sortModel= {}, page = '', per_page = '') {

        if (endpoint === 'city.json') {
            return new Promise((res, err) => {
                res(fakeDataCity)
            })
        }

        if (endpoint === 'team.json') {
            return new Promise((res, err) => {
                res(fakeDataTeam)
            })
        }

        if (endpoint === 'hooligan.json') {
            return new Promise((res, err) => {
                res(fakeDataHooligans)
            })
        }


        // if (controller) controller.abort();
        // controller = new AbortController();

        const axiosRequestOptions = {
            url: endpoint,
            method: 'GET',
            headers: authHeader(),
            // signal: controller.signal,
            params: {
                page: page,
                per_page: per_page,
                filters: filterModel,
                sort: sortModel,
            }
        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
    }

    async function getById(id) {

        if (endpoint === 'city.json') {
            return new Promise((res, err) => {
                res(fakeDataCity.data.find((el) => el.id === id))
            })
        }

        if (endpoint === 'team.json') {
            return new Promise((res, err) => {
                res(fakeDataTeam.data.find((el) => el.id === id))

            })
        }

        if (endpoint === 'hooligan.json') {
            return new Promise((res, err) => {
                res(fakeDataHooligans.data.find((el) => el.id === id))

            })
        }

        const axiosRequestOptions = {
            url: endpoint + '/' + id,
            method: 'GET',
            headers: authHeader()
        };

        const res = axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
        // controller = undefined

        return res

    }

    return {
        _delete,
        save,
        getAll,
        getById
    }
}


export default createService