import React from "react"
import { useDropzone } from "react-dropzone";
import { FormControl, FormHelperText, FormLabel } from "@mui/joy";
import { Icon } from "../../assets";
import { Text } from "../Typography";

const InputDropzoneElement = ({ id, name, label, hint, error, invalid, editing, required, multiple, disabled, readonly, className, onChange }) => {

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ id, name, label, hint, error, invalid, editing, required, multiple, disabled, readonly })
    const files = acceptedFiles.map(file => {
        { console.log('file', file) }
        return (
            <li key={file.path}>
                <div className="filePreview">
                    <Icon icon="faFile"></Icon>
                    <Text level="title-sm">{file.path}</Text>
                    <Text level="body-sm">{file.size} bytes</Text>
                </div>
            </li>
        );
    });

    if (!editing) readonly = true;

    let outerClass = "form-element-control";
    outerClass += (editing) ? " editing" : " reading";
    outerClass += (error) ? " error" : "";
    outerClass += (required) ? " required" : "";
    outerClass += (disabled) ? " disabled" : "";
    outerClass += (readonly) ? " readonly" : "";

    return (
        <div className={("form-element dropbox " + className).trim()}>
            <FormControl error={error} className={(invalid) ? outerClass + " form-element-error" : outerClass}>
                <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>

                <div {...getRootProps()} className="form-element-dropbox-area">
                    <input {...getInputProps({ onChange })} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag and drop some files here, or click to select files</p>
                    }
                    {
                        acceptedFiles ? <ul className="filesPreview">{files}</ul> : ''
                    }
                </div>

                <FormHelperText className="form-element-hint">
                    {(invalid) ? (
                        <>
                            {error ? error.message : 'Messaggio di errore'}
                        </>
                    ) : (
                        <>
                            {hint}
                        </>
                    )}
                </FormHelperText>
            </FormControl>
        </div>
    )
}

export default InputDropzoneElement;