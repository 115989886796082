import React from "react"

import { Box, FormControl, FormHelperText, FormLabel, Input, Typography } from "@mui/joy";
import { Controller } from "react-hook-form";
import { Icon } from '../../assets';

const InputNumber = (props) => {
    const { editing, required, name, control, label, autoFocus, placeholder, type = "number", hint, icon, haserror, disabled, minvalue, maxvalue, step, className = "" } = props
    let { readonly } = props;
    if (!editing) readonly = true;


    const slotProps = {
        input: {
            min: minvalue,
            max: maxvalue,
            step: step
        },
    };


    return (

        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {
                let generalClass = "form-element-control";
                generalClass += (editing) ? " editing" : " reading";
                generalClass += (invalid) ? " error" : "";
                generalClass += (required) ? " required" : "";
                generalClass += (disabled) ? " disabled" : "";
                generalClass += (readonly) ? " readonly" : "";
                return (
                    <div className={("form-element number " + className).trim()}>
                        <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                            <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>
                            <Input
                                id={name}
                                type={type}
                                name={name}
                                value={value}
                                error={invalid}
                                onChange={onChange}
                                variant="outlined"
                                autoComplete="off"
                                className="form-element-field"

                                autoFocus={autoFocus}
                                placeholder={placeholder}
                                disabled={disabled}
                                readOnly={readonly}
                                required={required}

                                slotProps={slotProps}

                                startDecorator={(icon) ? (
                                    <Icon icon={icon} />
                                ) : (
                                    <></>
                                )}
                                endDecorator={(haserror || error) ? (
                                    <Icon icon="faCircleExclamation" />
                                ) : (
                                    <></>
                                )}
                            />
                            <FormHelperText className="form-element-hint">
                                {(haserror || error) ? (
                                    <>
                                        {error ? error.message : 'Messaggio di errore'}
                                    </>
                                ) : (
                                    <>
                                        {hint}
                                    </>
                                )}
                            </FormHelperText>
                        </FormControl>
                    </div>
                )
            }}
        />

    );
};


export default InputNumber;
