import React, { Fragment, useEffect, useState } from 'react'
import { Box } from '@mui/joy'
import { DataGrid } from '@mui/x-data-grid'
import { Cell } from '../ListElements'
import ListToolbar from './ListToolbar'
import { useDataProvider } from '../../../mtska-frontend-data-provider'


const listId = Math.random().toString(16).slice(2);

const  pageSizeOptions =[5, 10, 15, 20, 100];

const List = ({
    dataProviderName,

    defaultSelectedValues,
    enableSelection,
    onValuesChange,

    leftButtons = [],
    rightButtons = [],

    className = "",
    limit = 20
}) => {

    const { items, total, loading, tableConfig, loadAll } = useDataProvider(dataProviderName);
    const [columns, setColumns] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [toolBarLeftButtons, settoolbarLeftButtons] = useState(leftButtons)
    const [toolBarRightButtons, settoolbarRightButtons] = useState(rightButtons)

    // FILTERS
    const [filterModel, setFilterModel] = useState({});

    // SORTING
    const [sortModel, setSortModel] = useState({});
    
    // PAGINATION
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: pageSizeOptions.includes(limit) ? limit: pageSizeOptions[0],
    });

    // LOAD DATA

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(total || 0);

    const rowCount = React.useMemo(() => {
        if (total !== undefined) {
            rowCountRef.current = total;
        }
        return rowCountRef.current;
    }, [total]);

    useEffect(() => {
        console.log(filterModel);
        loadAll && loadAll(filterModel, sortModel, paginationModel.page + 1, paginationModel.pageSize)
    }, [paginationModel, filterModel, sortModel])


    // *******************************************************************
    // MANAGE ROW SELECTION & ASSOCIATION
    // *******************************************************************

    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [viewedItems, setViewedItems] = useState([]);


    // call callback with sulle selected items object
    useEffect(() => {
        if (!enableSelection) return
        const selectedIems = rowSelectionModel.map(id => viewedItems[id])
        onValuesChange && onValuesChange(selectedIems);
    }, [rowSelectionModel]);



    // preset rowSelectionModel adn viewedItems
    useEffect(() => {
        if (!enableSelection) {
            return;
        }

        if (!Array.isArray(defaultSelectedValues)) {
            setRowSelectionModel([]);
            return;
        }

        const tmp= defaultSelectedValues.reduce((a, v) => ({ ...a, [v.id]: v}), {});
        setViewedItems(tmp);

        const selectedIemsIds= defaultSelectedValues.map(el => el._id);
        setRowSelectionModel(selectedIemsIds);
        
    }, [defaultSelectedValues, enableSelection]);

    // add items to viewedItems while changing pafe or serach .. to have the full row object available for association
    useEffect(() => {
        const tmp = {...viewedItems, ...items.reduce((a, v) => ({ ...a, [v.id]: v}), {})};
        setViewedItems(tmp);
    }, [items]);



    useEffect(() => {
        if (typeof tableConfig?.columnVisibilityModel !== 'undefined') {
            setColumnVisibilityModel(tableConfig.columnVisibilityModel);
        }
        if (Array.isArray(tableConfig?.columns)) {
            const columns = tableConfig.columns.map((cc) => {
                return {
                    flex: 1,
                    field: cc,
                    renderCell: (params) => {
                        console.log("Cell rendering", params.value?.value || params.formattedValue)
                        const type = tableConfig.columnsRendered[params?.field]?.type
                        const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName
                        return (
                            <Fragment>
                                <Cell
                                    type={type}
                                    dataProviderName={dataProviderName}
                                    fieldDataProviderName={fieldDataProviderName}
                                    field={params?.field}
                                    value={params.value?.value || params.formattedValue}
                                    row={params.row}
                                />
                            </Fragment>
                        )
                    }
                }
            })
            setColumns(columns)
        }
    }, [tableConfig])


    
    

    return (
        <Box className={(className+" list-system listy-system-" + listId).trim()}>
           
            <DataGrid
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                slots={{
                    toolbar: ListToolbar
                }}
                slotProps={{
                    toolbar: {
                        leftButtons: toolBarLeftButtons,
                        rightButtons: toolBarRightButtons,
                    }
                }}
                disableRowSelectionOnClick
                checkboxSelection={enableSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                getRowId={(row) => row._id}
                initialState={{
                    columns: {

                    },
                }}
                autoHeight={true}
                pagination
                columns={columns}
                rows={items ?? []}


                paginationMode="server"
                rowsPerPage={10}
                loading={loading}
                rowCount={rowCount}
                pageSizeOptions={pageSizeOptions}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}

                filterMode="server"
                onFilterModelChange={setFilterModel}

                sortingMode="server"
                onSortModelChange={setSortModel}

                keepNonExistentRowsSelected


                className={"list-wrapper list-wrapper-" + listId}
            />
        </Box>
    )
}

export default List



