import React, { useEffect, useState } from "react"
import { ajvResolver } from "@hookform/resolvers/ajv"
import { Card, CardContent, CardOverflow, Divider } from "@mui/joy"
import { fullFormats } from "ajv-formats/dist/formats"
import { useForm } from "react-hook-form"
import { CardActionArea } from "@mui/material"
import { Input } from ".."
import { useDataProvider } from "../../../mtska-frontend-data-provider"
import { useActivator } from "../../../mtska-frontend-modules/context"

const detailId = Math.random().toString(16).slice(2);

const Detail = ({ moduleId, dataProviderName, filters, title, label, icon = "" }) => {

    const { addActivatorCallable, call } = useActivator();

    const localHandleSubmit = (data) => {
        save ? save(data) : alert('no save() method available!')
        call(`drawer_close_${moduleId}`)
    }

    useEffect(() => {
        addActivatorCallable(`form_submit_${moduleId}_${filters?.id}`, () => {
            handleSubmit(localHandleSubmit)()
        })
    }, [])

    const { item, save, loadOne, formConfig, errorMessage, errors,} = useDataProvider(dataProviderName);

    const [defaultValues, setDefaultValues] = useState({})

    // load from dataProvider
    useEffect(() => {
        if (!filters?.id) return
        loadOne(filters?.id)
    }, [filters])

    // get item from dataProvider
    useEffect(() => {
      
        if (!filters?.id) return
        if (!item?.data) return
        setDefaultValues(item.data)
    }, [item])

  

    // form 
    const { handleSubmit, control, setError } = useForm({
        values: defaultValues,
        resolver: ajvResolver(formConfig.validation, { formats: fullFormats, }),
    })

    useEffect(() => {
        if(typeof errors !== 'object' ) return;

        Object.entries(errors).map(
            ([k, v], i) =>  setError(k, { type: 'server', message: v[0] })
        )
    }, [errors])



    const [editing, setEditing] = useState(false)

    const activeEditing = () => {
        setEditing(true)
    }

    const deactivateEditing = () => {
        setEditing(false)
    }

   
    

    
   


    return (
        <Card className="form-wrapper">
            {
            /* 
            @todo: il titolo della form
            <CardOverflow className="form-section-header-wrapper">
                <CardContent className="form-section-header-content">
                    <FormHeader title={title} label={label} icon={icon} />

                </CardContent>
                <Divider inset="context" />
            </CardOverflow> 
            */
            }


            <CardActionArea className="form-section-fields-wrapper" focusVisibleClassName="none" disableRipple={editing} disableTouchRipple={editing} onClick={activeEditing}  >
                <CardContent className="form-section-fields-content">
                    {typeof formConfig.fields === 'object' && Object.entries(formConfig.fields).map(([k, config]) => {
                        return <Input editing={editing} control={control} key={k} name={k} label={k} {...config} />
                    })}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default Detail;