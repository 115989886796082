import React, { Fragment } from "react"
import CellDrawer from "./CellDrawer";
import CellEmail from "./CellEmail";
import CellSimple from "./CellSimple";
import CellPeople from "./CellPeople";
import CellLegalentities from "./CellLegalentities";
import CellBis from "./CellBis";

const Cell = ({ type, value, row, dataProviderName, fieldDataProviderName }) => {

  if (type === 'detail') return (
    <Fragment>
      <CellDrawer
        dataProviderName={dataProviderName}
        openButtonLabel={value}
        filters={row}
        showDetail
      />
    </Fragment>
  )

  if (type === 'relation-list') return (
    <CellDrawer
      dataProviderName={fieldDataProviderName}
      value={value}
      filters={row}
    />
  )

  if (type === 'relation-detail') return (
    <CellDrawer
      dataProviderName={fieldDataProviderName}
      value={value}
      filters={row}
      showDetail
    />
  )

  if (type === 'email') return (
    <CellEmail
      value={value}
    />
  )

  if (type === 'people') return (
    <CellPeople
      value={value}
    />
  )

  if (type === 'legalentities') return (
    <CellLegalentities
      value={value}
    />
  )

  if (type === 'bis') return (
    <CellBis
      value={value}
    />
  )

  return (
    <CellSimple
      value={value}
      type={type}
    />
  )

};

export default Cell
