import React, { Fragment } from 'react';

import { DrawerActivator } from '../Drawer';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { Button } from '../Button';
import KPI from '../Chart/KPI';
import { Box } from '@mui/joy';


const ModuleDataContainer = ({
    moduleId,
    ListComponent,
    DetailComponent,
    showDetail = false,
    title = 'Module title',
    dataProviderName,
    filters = {},
    defaultValue,
    enableSelection,
    setListValues,
    handleFormSave,
}) => {

    const { widgets } = useDataProvider(dataProviderName);



    return (
        <Fragment>
            {showDetail ? (
                <DetailComponent
                    moduleId={moduleId}
                    dataProviderName={dataProviderName}
                    title={title}
                    filters={filters}
                />
            ) : (
                <>
                    <Box className="mtksa-chart-wrapper">
                        <KPI
                            title="Report BI"
                            icon="faDashboard"
                            score={widgets.bi?.total}>
                        </KPI>
                        <KPI
                            title="People"
                            icon="faPeople"
                            score={widgets.person?.total}>
                        </KPI>
                        <KPI
                            title="Legal entities"
                            icon="faBuilding"
                            score={widgets.legalentity?.total}>
                        </KPI>
                    </Box>
                    <ListComponent

                        dataProviderName={dataProviderName}
                        defaultSelectedValues={defaultValue}
                        enableSelection={enableSelection}
                        onValuesChange={setListValues}

                        leftButtons={[
                            <DrawerActivator
                                openButtonLabel={"New"}
                                drawerTitle={"New Item"}
                                dataProviderName={dataProviderName}
                                showDetail={true}
                                // onValuesChange={setFormValues}
                                action={<Button
                                    className="button-main"
                                    onClick={handleFormSave}
                                >Save form</Button>}
                            />
                        ]}
                        rightButtons={[]}
                    />
                </>
            )}
        </Fragment>
    )
}

export default ModuleDataContainer;

