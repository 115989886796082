
export const handleSuccess = (response) => {

    console.log("axios - handleSuccess", response);

    // if (response.status === 401) {
    //     // userService.logout();
    //     localStorage.removeItem('token');
    //     //  window.location.reload(true);
    // }

    return Promise.resolve(response.data)


}

export const handleError = (error) => {

    console.log("axios - handleError", error);

    if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.reload(true);
    }

    console.log("axios - handleError", error.response);


    return Promise.reject(error.response.data)


}

