import React from "react"
import InputTextControl from "./InputTextControl";
import InputHiddenControl from "./InputHiddenControl";
import InputTextarea from "./InputTextarea";
import InputDateControl from "./InputDateControl";
import InputEmailControl from "./InputEmailControl";
import InputFileControl from "./InputFileControl";
import InputDropzoneControl from "./InputDropzoneControl";
import InputNumber from "./InputNumber";
import InputSelect from "./InputSelect";
import InputRadio from "./InputRadio";
import InputCheckbox from "./InputCheckbox";
import InputSwitch from "./InputSwitch";
import InputRelationship from "./InputRelationship";

const Input = (props) => {


    if (props.type === 'text')          return <InputTextControl {...props} />
    if (props.type === 'file')          return <InputFileControl {...props} />
    if (props.type === 'dropzone')      return <InputDropzoneControl {...props} />
    if (props.type === 'hidden')        return <InputHiddenControl {...props} />
    if (props.type === 'textarea')      return <InputTextarea {...props} />
    if (props.type === 'number')        return <InputNumber {...props} />
    if (props.type === 'date')          return <InputDateControl {...props} />
    if (props.type === 'email')         return <InputEmailControl {...props} />
    if (props.type === 'select')        return <InputSelect {...props} />
    if (props.type === 'radio')         return <InputRadio {...props} />
    if (props.type === 'checkbox')      return <InputCheckbox {...props} />
    if (props.type === 'switch')        return <InputSwitch {...props} />
    if (props.type === 'relationship')  return <InputRelationship {...props} />

};

export default Input
