import React from "react"
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import { Box, FormControl, FormHelperText, FormLabel, IconButton, List, ListItem, ListItemButton, ListItemContent } from "@mui/joy";
import { Controller } from "react-hook-form";
import { DrawerActivator } from "../Drawer";
import { Button } from '../Button';

const InputRelationship = (props) => {
    const { relation, editing, required, name, control, label, autoFocus, placeholder, type = "relationship", hint, icon, haserror, disabled, className = "" } = props
    let { readonly } = props;
    if (!editing) readonly = true;

   

    const [selection, setSelection] = useState([]);


    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {

                let generalClass = "form-element-control";
                generalClass += (editing) ? " editing" : " reading";
                generalClass += (invalid) ? " error" : "";
                generalClass += (required) ? " required" : "";
                generalClass += (disabled) ? " disabled" : "";
                generalClass += (readonly) ? " readonly" : "";


                const handleListValueChange = (values) => {
                    if (JSON.stringify(selection) !== JSON.stringify(values)) {
                        setSelection(values)
                    }
                }

                const handleSelectSave = () => {
                    onChange(selection)
                }

                const handleItemRemove = (toBeRemoved) => {
                    onChange(value.filter((el) => el !== toBeRemoved))
                }

                return (
                    <div className={("form-element relationship " + className).trim()}>
                        <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                            <Box className="form-element-relationship-value">
                                <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>

                            </Box>
                            <Box className="form-element-relationship-options" >
                                <List variant="plain" >
                                    {Array.isArray(value) && value.map((v, k) => (
                                        <ListItem key={k} variant="outlined" sx={{ borderRadius: 10, marginBottom: 1 }}>
                                            <ListItemContent>
                                                {v.name}
                                            </ListItemContent>
                                            {editing && (<IconButton onClick={() => handleItemRemove(v)} ><Close /></IconButton>)}
                                        </ListItem>
                                    ))}
                                    {(!editing && Array.isArray(value) && value.length === 0) && (
                                        <ListItem variant="plain" sx={{ border: 0, marginBottom: 1 }}>
                                            <ListItemContent >none...</ListItemContent>
                                        </ListItem>
                                    )}
                                </List>
                                {editing && (
                                    <DrawerActivator
                                        dataProviderName={relation.providerName}
                                        openButtonLabel={'Add'}
                                        drawerTitle={'Manage the relationship'}
                                        onListValuesChange={handleListValueChange}
                                        defaultValue={value}
                                        enableSelection
                                        action={<Button className="button-main" onClick={handleSelectSave}>Associate</Button>} />
                                )}

                            </Box>
                            <FormHelperText className="form-element-hint">
                                {(haserror || error) ? (
                                    <>
                                        {error ? error.message : 'Messaggio di errore'}
                                    </>
                                ) : (
                                    <>
                                        {hint}
                                    </>
                                )}
                            </FormHelperText>
                        </FormControl>
                    </div>
                )
            }}
        />
    );
}

export default InputRelationship
